
import Vue from "vue";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { ActionTypes } from "@/store/messages/action-types";
import { TreeActions } from "@/store/general/action-types";
import HTTP from "@/services/HttpStatusCodes";
import StaffApi from "@/api/staff";
import { bufferMethods } from "@/services/message-buffer-helper";
// import { mapActions } from "vuex";
import UnreadMessagesTable from "@/components/UnreadMessagesTable.vue";
import TTP from "@/services/template-tree-parser";
export default Vue.extend({
  name: "Unread",
  components: {
    UnreadMessagesTable,
  },
  data() {
    return {
      filter: "",
      selected: [],
    };
  },
  computed: {
    ...mapGetters({
      messages: "Message/allMessages",
      unreadMessages: "Message/unreadMessages",
      unreadCount: "Message/allCount",
      loadedMessages: "Message/loadedMessages",
      isManager: "Auth/isManager",
      outboundLoaded: "Message/outboundLoaded",
    }),
  },
  methods: {
    ...mapActions({
      loadReadMessagesAction: "Message/" + ActionTypes.LOAD_READ_MESSAGES,
      loadMessagesAction: "Message/" + ActionTypes.LOAD_MESSAGES,
      loadOutboundQueue: "Message/" + ActionTypes.LOAD_OUTBOUND_QUEUE,
    }),
    mySort(rows: any, sortBy: any, descending: any) {
      const data = [...rows];
    },
    getSelectedString() {
      return this.selected.length === 0
        ? ""
        : `${this.selected.length} record${
            this.selected.length > 1 ? "s" : ""
          } selected of ${this.messages.length}`;
    },
    toggleInbox(filter: any) {
      this.$store.commit("Message/CLEAR_STATE");
      this.loadMessages(filter);
    },
    loadMessages(filter: any) {
      this.loadMessagesAction(filter)
        .then((response) => {
          // bufferMethods.loadBuffer();
          this.$q.notify({
            position: "top-right",
            type: "positive",
            message: "Messages loaded successfully",
          });
        })
        .catch((error) => {
          console.log("first Error catching");
          console.log(error);
          if (error.response) {
            const code = error.response.status;
            switch (code) {
              case HTTP.NOT_FOUND:
                this.$q.notify({
                  position: "top-right",
                  type: "warning",
                  message: "Page Not Found",
                });
                break;

              case HTTP.UNAUTHORIZED:
                this.$q.notify({
                  position: "top-right",
                  type: "warning",
                  message: "Please Login, Session Expired",
                });
                break;

              case HTTP.SERVER_ERROR:
                this.$q.notify({
                  position: "top-right",
                  type: "warning",
                  message: "Server Error",
                });
                break;
            }
          } else {
            return;
          }
        });
    },
  },
  created() {
    TTP.createList();
    // this.$store.dispatch("General/" + TreeActions.GET_INVENTORY_CONFIGS);
    if (!this.loadedMessages) {
      console.log("It is my fault Unread home");
      // let type_ = 0;
      const filter = {
        isManager: +this.isManager,
        showAll: 0,
        accountAbbrev: "KC",
      };
      // if (this.isManager) {
      //   const filter = {isManager:this.isManager }
      // }

      // StaffApi.setUpShipsiteToken()
      this.loadReadMessagesAction();
      this.loadMessages(filter);
    }
    const shipsiteToken = localStorage.getItem("shipsiteToken");
    if (!shipsiteToken) {
      console.log("No Token");
      StaffApi.setUpShipsiteToken().catch((error) => {
        console.log("Unable to setup Shipsite Token");
        console.log(error);
      });
    }
    if (!this.outboundLoaded) {
      this.loadOutboundQueue();
    }
  },
});
