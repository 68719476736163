import { render, staticRenderFns } from "./UnreadMessagesTable.vue?vue&type=template&id=26cc2d7c&scoped=true"
import script from "./UnreadMessagesTable.vue?vue&type=script&lang=ts"
export * from "./UnreadMessagesTable.vue?vue&type=script&lang=ts"
import style0 from "./UnreadMessagesTable.vue?vue&type=style&index=0&id=26cc2d7c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26cc2d7c",
  null
  
)

export default component.exports
import QList from 'quasar/src/components/item/QList.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QVirtualScroll from 'quasar/src/components/virtual-scroll/QVirtualScroll.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QList,QSpinner,QItem,QItemSection,QItemLabel,QBtnToggle,QChip,QCard,QCardSection,QCheckbox,QBtn,QTooltip,QIcon,QVirtualScroll,QAvatar});
