
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { mapActions } from "vuex";
import { ActionTypes } from "@/store/messages/action-types";
import { ModuleNames } from "@/services/store-helper";
import { ButtonType } from "@/components/ButtonDeleteMessage.vue";
import SelectAccount from "@/components/SelectAccount.vue";
import ButtonDeleteMessage from "./ButtonDeleteMessage.vue";
import { MessageMutations } from "@/store/messages/mutation-types";
import { MutationTypes } from "@/store/general/mutation-types";
import KeyboardShortcuts from "@/models/KeyboardShortcuts";
import AccountAbbrev from "@/services/AccountAbbrev";

export default Vue.extend({
  components: { ButtonDeleteMessage, SelectAccount },
  name: "UnreadMessagesTable",
  props: { loading: Boolean },

  data() {
    return {
      selectedAccount: "",
      deleteConfirmModal: false,
      itemToDelete: [],
      selection: [],
      select: false,
      inboxType: "ManagerView",
      options: [
        {
          label: "For Admin",
          value: "goog",
          description: "Sent to admin",
          icon: "mail",
        },
        {
          label: "All Inbox",
          value: "fb",
          description: "All messages",
          icon: "bluetooth",
        },
      ],
      page: 2,
      messageFilter: {
        hasOrder: false,
        hasMessage: false,
      },
      accountFilter: "all",
    };
  },
  computed: {
    ...mapGetters({
      allMessages: "Message/unreadMessages",
      getOrder: "Message/hasOrder",
      readMessageIds: "Message/readMessageIds",
      noOrder: "Message/noOrder",
      isNextPage: "Message/isNextPage",
      isManager: "Auth/isManager",
      accountAbbreviations: "General/accountAbbreviations",
      isInBuffer: "Buffer/getMessageInBuffer",
    }),
    selectionIds(): string[] {
      const idsList: string[] = [];
      this.selection.forEach((item: any) => {
        idsList.push(item.id);
      });
      return idsList;
    },
    tableButton(): ButtonType {
      return {
        bulk: false,
        table: true,
        page: false,
      };
    },
    bulkButton(): ButtonType {
      return {
        bulk: true,
        table: false,
        page: false,
      };
    },
    isManager(): boolean {
      return this.$store.getters["Auth/isManager"];
    },
    messageObj(): any {
      const orders = this.messageFilter.hasOrder;
      const message = this.messageFilter.hasMessage;
      if (orders) {
        return Object.freeze(this.getOrder);
      } else if (message) {
        return Object.freeze(this.noOrder);
      }
      return Object.freeze(this.allMessages);
    },
    selectionCount(): any {
      return this.selection.length;
    },
    selectionExists() {
      if (this.selectionCount > 0) {
        return true;
      } else return false;
    },
    currentAccountAbbrev(): any {
      return this.$store.state.Message.currentInboxAccount;
    },
  },
  methods: {
    ...mapMutations({
      setIsNextPage: "Message/" + MessageMutations.SET_IS_NEXT_PAGE,
      setInboxType: "Message/" + MessageMutations.SET_INBOX_TYPE,
    }),
    ...mapActions({
      markAsReadAction: ModuleNames.MESSAGE_MODULE + ActionTypes.MARK_AS_READ,
      deleteMessageAction:
        ModuleNames.MESSAGE_MODULE + ActionTypes.DELETE_MESSAGE,
      sendToAdminAction: ModuleNames.MESSAGE_MODULE + ActionTypes.SEND_TO_ADMIN,
      bulkSendToAdminAction:
        ModuleNames.MESSAGE_MODULE + ActionTypes.BULK_SEND_TO_ADMIN,
    }),
    itemInBuffer(itemID: any) {
      const messageObj = this.isInBuffer(itemID);
      if (messageObj) {
        return true;
      }
      return false;
    },
    keyPress(event: KeyboardEvent) {
      const keyMap: KeyboardShortcuts[] = [
        {
          key: "a",
          fn: this.viewAdminMessages,
        },
        {
          key: "m",
          fn: this.viewAllMessages,
        },
      ];
      if (event.ctrlKey) {
        const keyPressed = keyMap.find(({ key }) => {
          return key === event.key;
        });
        if (keyPressed) {
          event.preventDefault();
          keyPressed.fn();
        } else {
          return;
        }
      }
    },
    clearSelection(): void {
      this.selection = [];
    },
    // TODO: Need to rework this whole thing to be DRY
    toggleInbox() {
      const _type = this.inboxType;

      let filter = {};

      if (_type == "StaffView") {
        filter = {
          isManager: 0,
          showAll: 0,
          accountAbbrev: this.currentAccountAbbrev,
        };
      } else if (_type == "ManagerView") {
        filter = {
          isManager: 1,
          showAll: 0,
          accountAbbrev: this.currentAccountAbbrev,
        };
      } else if (_type == "AllView") {
        filter = {
          isManager: 1,
          showAll: 1,
          accountAbbrev: this.currentAccountAbbrev,
        };
      }
      this.setInboxType(_type);
      console.log(_type);
      this.$emit("toggleInbox", filter);
    },
    viewAdminMessages() {
      const _type = "ManagerView";
      this.inboxType = _type;
      this.toggleInbox();
    },
    viewAllMessages() {
      const _type = "StaffView";
      this.inboxType = _type;
      this.toggleInbox();
    },
    open(item: any, index?: number) {
      this.setIsNextPage(false);
      if (item.hasOrder) {
        this.$router.push({
          name: "OrderDetail",
          params: {
            messageId: item.id,
            orderId: item.orderId,
            accountAbbrev: item.accountAbbrev as AccountAbbrev,
          },
        });
      } else {
        this.$router.push({
          name: "MessageDetail",
          params: {
            messageId: item.id,
            accountAbbrev: item.accountAbbrev as AccountAbbrev,
          },
        });
      }
    },
    isRead(msgId: string): boolean {
      let check = false;

      const obj = this.readMessageIds;
      if (obj != null) {
        check = obj.includes(msgId);
        return check;
      }
      return check;
    },
    color(id: any) {
      let check = false;
      const darkMode = this.$q.dark.isActive;
      const obj = this.readMessageIds;
      if (obj != null) {
        check = obj.includes(id);
        if (check && !darkMode) {
          return "";
        } else if (check && darkMode) {
          return "";
        } else if (!check && darkMode) {
          return "bg-grey-9";
        } else if (!check && !darkMode) {
          return "bg-grey-3";
        }
      }
    },

    sendToAdmin(id: string) {
      this.sendToAdminAction(id)
        .then(() => {
          this.$q.notify({
            position: "top",
            type: "positive",
            message: "Sent to admin Successfully",
          });
        })
        .catch((error) => {
          this.$q.notify({
            position: "top",
            type: "warning",
            message: "Error on send :(",
          });
        });
    },
    bulkSendToAdmin() {
      const items: any = this.selection;
      if (items) {
        for (const item of items) {
          this.sendToAdminAction(item.id)
            .then(() => {
              this.$q.notify({
                position: "top-right",
                type: "positive",
                message: "Sent Item To Admin",
              });
              this.selection = [];
            })
            .catch((error) => {
              this.$q.notify({
                position: "top-right",
                type: "warning",
                message: "Bumped into and error",
              });
            });
        }
      }
      return;
    },
    deleteMsg(item: number) {
      this.deleteMessageAction(item).then(() => {
        this.$q.notify({
          position: "top",
          type: "success",
          message: "Successfully deleted message",
        });
      });
    },
    markAsRead(id: number) {
      this.markAsReadAction(id).then(() => {
        this.$q.notify({
          position: "top-right",
          type: "positive",
          message: "Marked As Read!",
        });
      });
    },
    clickedThis() {
      if (this.select == true) {
        this.selection = this.messageObj;
      } else if (this.select == false) {
        this.selection = [];
      } else this.selection;
    },
  },
  created() {
    const inboxView = this.$store.state.General.inboxType;
    if (inboxView) {
      this.inboxType = inboxView;
    }
    window.addEventListener("keydown", this.keyPress);
    // @ts-expect-error
    this.keyPress(KeyboardEvent);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.keyPress);
  },
});
