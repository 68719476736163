
import Vue from "vue";
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";
import { MessageMutations } from "@/store/messages/mutation-types";
import { TreeActions } from "@/store/general/action-types";
import { ActionTypes } from "@/store/messages/action-types";
import { Info, Warn } from "@/services/notif-utils";
export default Vue.extend({
  name: "SelectAccount",
  data() {
    return {
      loading: false,
      selectedAccount: "",
    };
  },
  computed: {
    ...mapGetters({
      accountAbbreviations: "General/accountAbbreviations",
      loadedMessages: "Message/loadedMessages",
      // TODO: need to refactor this to be drier
      isManager: "Auth/isManager",
    }),
    currentAccount(): any {
      return this.$store.state.Message.currentInboxAccount;
    },
    inboxType(): any {
      return this.$store.state.Message.currentInboxType;
    },
    inventoryConfigLoaded(): boolean {
      const listLength = this.$store.state.General.inventoryConfigs.length;
      if (listLength > 0) {
        return true;
      }
      return false;
    },
  },
  // watch: {
  //   selectedAccount() {
  //     this.selectAccount();
  //   },
  // },
  methods: {
    ...mapActions({
      loadAccountConfigs: "General/" + TreeActions.GET_INVENTORY_CONFIGS,
      loadMessages: "Message/" + ActionTypes.LOAD_MESSAGES,
    }),
    ...mapMutations({
      setCurrentAccountAbbrev:
        "Message/" + MessageMutations.SET_CURRENT_ACCOUNT,
    }),
    selectAccount() {
      // FLAGGED: For Dry refactor
      console.log("IT is my fault select ACcount");
      this.loading = true;
      let filter = {
        isManager: +this.isManager,
        showAll: 0,
        accountAbbrev: this.selectedAccount,
      };
      if (this.inboxType == "AllView") {
        filter["showAll"] = 1;
      }
      if (this.inboxType == "StaffView") {
        filter["isManager"] = 0;
      }
      this.setCurrentAccountAbbrev(this.selectedAccount);
      this.loadMessages(filter)
        .then(() => {
          this.loading = false;
          return Info(`Now Viewing ${this.selectedAccount} Messages`);
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
  created() {
    this.selectedAccount = this.currentAccount;
    if (!this.loadedMessages) {
      this.selectAccount();
    }
    if (!this.inventoryConfigLoaded) {
      this.loading = true;
      this.loadAccountConfigs()
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          Warn("Problem Loading Inventory Configs for Dropdown");
        });
    }
  },
});
